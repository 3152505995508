import $ from "jquery";
/*------------------------------------------
	= mobile menu
-------------------------------------------*/
$('.xb-nav-hidden li.menu-item-has-children > a').append('<span class="xb-menu-toggle"></span>');
$('.xb-header-menu li.menu-item-has-children, .xb-menu-primary li.menu-item-has-children').append('<span class="xb-menu-toggle"></span>');
$('.xb-menu-toggle').on('click', function () {
	if (!$(this).hasClass('active')) {
		$(this).closest('ul').find('.xb-menu-toggle.active').toggleClass('active');
		$(this).closest('ul').find('.sub-menu.active').toggleClass('active').slideToggle();
	}
	$(this).toggleClass('active');
	$(this).closest('.menu-item').find('> .sub-menu').toggleClass('active');
	$(this).closest('.menu-item').find('> .sub-menu').slideToggle();
});

$('.xb-nav-hidden li.menu-item-has-children > a').on('click', function (e) {
	var target = $(e.target);
	if ($(this).attr('href') === '#' && !(target.is('.xb-menu-toggle'))) {
		e.stopPropagation();
		if (!$(this).find('.xb-menu-toggle').hasClass('active')) {
			$(this).closest('ul').find('.xb-menu-toggle.active').toggleClass('active');
			$(this).closest('ul').find('.sub-menu.active').toggleClass('active').slideToggle();
		}
		$(this).find('.xb-menu-toggle').toggleClass('active');
		$(this).closest('.menu-item').find('> .sub-menu').toggleClass('active');
		$(this).closest('.menu-item').find('> .sub-menu').slideToggle();
	}
});
$(".xb-nav-mobile").on('click', function () {
	$(this).toggleClass('active');
	$('.xb-header-menu').toggleClass('active');
});

$(".xb-menu-close, .xb-header-menu-backdrop").on('click', function () {
	$(this).removeClass('active');
	$('.xb-header-menu').removeClass('active');
});

if ($(".accordion_box").length) {
	$(".accordion_box").on("click", ".acc-btn", function () {
		var outerBox = $(this).parents(".accordion_box");
		var target = $(this).parents(".accordion");

		if ($(this).next(".acc_body").is(":visible")) {
			$(this).removeClass("active");
			$(this).next(".acc_body").slideUp(300);
			$(outerBox).children(".accordion").removeClass("active-block");
		} else {
			$(outerBox).find(".accordion .acc-btn").removeClass("active");
			$(this).addClass("active");
			$(outerBox).children(".accordion").removeClass("active-block");
			$(outerBox).find(".accordion").children(".acc_body").slideUp(300);
			target.addClass("active-block");
			$(this).next(".acc_body").slideDown(300);
		}
	});
}