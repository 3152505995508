const Breadcrumb = ({ data , image }) => {
    const headerStyle={
        backgroundColor: "#607D8B",
        backgroundImage: `url(${image})`,
        backgroundSize: "cover"    
    }
    return (
        <section className="breadcrumb ul_li_center bg_img" style={headerStyle}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-9">
                        <div className="breadcrumb__content">
                            <h2 className="breadcrumb__title">{data.title}</h2>
                            <ul className="breadcrumb__list clearfix">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{data.title}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Breadcrumb;