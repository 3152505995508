import React, { useState, useEffect } from 'react';
import MenuItems from './MenuItems';
import getBaseUrl from '../../base/htttp';

function MonbileMenu() {
	const [data, setData] = useState([]);
	const getData = () => {
		fetch(getBaseUrl() + "menu", { method: 'POST' })
			.then((response) => response.json())
			.then((result) => {
				setData(result.data);
			});
	}

	useEffect(() => {
		getData();
	}, []);

	return (
		<nav className="main-menu collapse navbar-collapse">
			<ul>
				{data.map((menu, index) => {
					return <MenuItems items={menu} key={index} />;
				})}
			</ul>
		</nav>
	);
}

export default MonbileMenu;