import $ from "jquery";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
//import 'slick-carousel/slick/slick';
import SlickSlider from '../../src/core/slick/slick.js';
import '../js/menu';
import './youtube'

function init(){
	SlickSlider();
	$('#preloader').delay(0).fadeOut('fast');
	new Swiper(".testimonial-slider", {
		modules: [Navigation, Pagination],
		loop: true,
		spaceBetween: 30,
		speed: 400,
		slidesPerView: 2,
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
		autoplay: {
			enabled: true,
			delay: 6000
		},
		navigation: {
			nextEl: '.xb-swiper-arrow-next',
			prevEl: '.xb-swiper-arrow-prev',
		},
		breakpoints: {
			'1600': {
				slidesPerView: 2,
			},
			'768': {
				slidesPerView: 2,
			},
			'576': {
				slidesPerView: 1,
			},
			'0': {
				slidesPerView: 1,
			},
		},
	});
	$('.popup-video').simpleLightboxVideo();
	$('.testimonial-active').not('.slck-initiailized').slick({
		dots: true,
		infinite: true,
		arrows: false,
		speed: 1000,
		slidesToShow:3,
		slidesToScroll: 2,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 2,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});
}
export default init;