import * as DOMPurify from 'dompurify';
import axios from 'axios';

const Content = ({ htmldata }) => {

    const sanitizedContent = DOMPurify.sanitize(htmldata, {
        ADD_TAGS: ['iframe'],
        ADD_ATTR: ['src'],
    });
    const handleSubmit = (event) => {
        console.log("hi");
        event.preventDefault();
        const form = event.target;
        // Prepare form data for submission
        const formData = new FormData(form);
        // Convert FormData to JSON
        const formJson = {};
        formData.forEach((value, key) => {
            formJson[key] = value;
        });
        const actionURL=form.action;
        
        axios.post(actionURL, formData)
        .then(response => {
            form.reset();
            form.append(response.data.message);
        })
        .catch(error => {
            form.append('Error submitting form:');
        });
    };

    const handleCheckbox=(event) =>{
        event.preventDefault();
        //console.log("Hello ck");
    }
    return (
        <div dangerouslySetInnerHTML={{ __html: sanitizedContent }}
            ref={el => {
                if (el) {
                    const form = el.querySelector('form');
                    console.log(form);
                    if (form) {
                        form.onsubmit = handleSubmit;
                    }
                    const requiredCheckboxes = el.querySelectorAll('input[type="checkbox"]');
                    if (requiredCheckboxes) {
                        //console.log("IN")
                        requiredCheckboxes.onChange = handleCheckbox;
                    }
                }
            }}
        />
    );
};

export default Content;