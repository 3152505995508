import React from "react";
import HeaderMenu from "./HeaderMenu";
import MonbileMenu from "./MonbileMenu";
import { useState, useEffect } from "react";
import getBaseUrl from "../../base/htttp";
import 'szmigiel-meanmenu/jquery.meanmenu.min'

function Header1() {
  const [topbar, setTopbar] = useState([]);
  const [ls, setLs] = useState([]);
  const [scrolled, setScrolled] = React.useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    }
    else {
      setScrolled(false);
    }
  }
  useEffect(() => {
    fetch(getBaseUrl() + "topbar", { method: 'POST' })
      .then((response) => response.json())
      .then((result) => {
        setTopbar(result.data.content);
        setLs(result.data.ls);
      });
  }, []);

  return (
    <>
      <header id="xb-header-area" className="header-area header-style-one is-sticky">
        <div className="xb-header">
          <div className="container">
            <div className="header__wrap ul_li_between">
              {ls.display_logo ? (
                <div className="header-logo">
                  <a href="/"><img src={ls.logo_url} alt="Bellfield Logo" /></a>
                </div>
              ) : (
                <div className="logo-text">
                  <h1>{ls.site_title}</h1>
                  <p>{ls.site_tagline}</p>
                </div>
              )}
              <div className="main-menu__wrap ul_li navbar navbar-expand-lg">
                <HeaderMenu />
                <div className="xb-header-wrap">
                  <div className="xb-header-menu">
                    <MonbileMenu />
                  </div>
                  <div className="xb-header-menu-backdrop"></div>
                </div>
              </div>
              <div className="header-contact d-none d-lg-block">
                <a href="tel:1300121227"><svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.4175 9.40566C15.0413 9.40566 14.7438 9.09941 14.7438 8.73191C14.7438 8.40816 14.42 7.73441 13.8775 7.14816C13.3437 6.57941 12.7575 6.24691 12.2675 6.24691C11.8913 6.24691 11.5938 5.94066 11.5938 5.57316C11.5938 5.20566 11.9 4.89941 12.2675 4.89941C13.1425 4.89941 14.0613 5.37191 14.8663 6.22066C15.6188 7.01691 16.1 8.00566 16.1 8.72316C16.1 9.09941 15.7938 9.40566 15.4175 9.40566Z" fill="white" />
                  <path d="M18.5763 9.40625C18.2 9.40625 17.9025 9.1 17.9025 8.7325C17.9025 5.62625 15.3738 3.10625 12.2763 3.10625C11.9 3.10625 11.6025 2.8 11.6025 2.4325C11.6025 2.065 11.9 1.75 12.2675 1.75C16.1175 1.75 19.25 4.8825 19.25 8.7325C19.25 9.1 18.9438 9.40625 18.5763 9.40625Z" fill="white" />
                  <path d="M10.3162 12.4337L7.455 15.295C7.14 15.015 6.83375 14.7262 6.53625 14.4287C5.635 13.5187 4.82125 12.565 4.095 11.5675C3.3775 10.57 2.8 9.5725 2.38 8.58375C1.96 7.58625 1.75 6.6325 1.75 5.7225C1.75 5.1275 1.855 4.55875 2.065 4.03375C2.275 3.5 2.6075 3.01 3.07125 2.5725C3.63125 2.02125 4.24375 1.75 4.89125 1.75C5.13625 1.75 5.38125 1.8025 5.6 1.9075C5.8275 2.0125 6.02875 2.17 6.18625 2.3975L8.21625 5.25875C8.37375 5.4775 8.4875 5.67875 8.56625 5.87125C8.645 6.055 8.68875 6.23875 8.68875 6.405C8.68875 6.615 8.6275 6.825 8.505 7.02625C8.39125 7.2275 8.225 7.4375 8.015 7.6475L7.35 8.33875C7.25375 8.435 7.21 8.54875 7.21 8.68875C7.21 8.75875 7.21875 8.82 7.23625 8.89C7.2625 8.96 7.28875 9.0125 7.30625 9.065C7.46375 9.35375 7.735 9.73 8.12 10.185C8.51375 10.64 8.93375 11.1037 9.38875 11.5675C9.70375 11.8737 10.01 12.1712 10.3162 12.4337Z" fill="white" />
                  <path d="M19.2238 16.0389C19.2238 16.2839 19.18 16.5377 19.0925 16.7827C19.0663 16.8527 19.04 16.9227 19.005 16.9927C18.8563 17.3077 18.6638 17.6052 18.41 17.8852C17.9813 18.3577 17.5088 18.6989 16.975 18.9177C16.9663 18.9177 16.9575 18.9264 16.9488 18.9264C16.4325 19.1364 15.8725 19.2502 15.2688 19.2502C14.3763 19.2502 13.4225 19.0402 12.4163 18.6114C11.41 18.1827 10.4038 17.6052 9.40627 16.8789C9.06502 16.6252 8.72377 16.3714 8.40002 16.1002L11.2613 13.2389C11.5063 13.4227 11.725 13.5627 11.9088 13.6589C11.9525 13.6764 12.005 13.7027 12.0663 13.7289C12.1363 13.7552 12.2063 13.7639 12.285 13.7639C12.4338 13.7639 12.5475 13.7114 12.6438 13.6152L13.3088 12.9589C13.5275 12.7402 13.7375 12.5739 13.9388 12.4689C14.14 12.3464 14.3413 12.2852 14.56 12.2852C14.7263 12.2852 14.9013 12.3202 15.0938 12.3989C15.2863 12.4777 15.4875 12.5914 15.7063 12.7402L18.6025 14.7964C18.83 14.9539 18.9875 15.1377 19.0838 15.3564C19.1713 15.5752 19.2238 15.7939 19.2238 16.0389Z" fill="white" />
                </svg> <span>1300 121 227 </span></a>
              </div>
              <div className="header-bar-mobile side-menu d-lg-none">
                <a className="xb-nav-mobile" href="#">
                  <i className="far fa-bars"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header1;